import React, { ChangeEvent, FC, useRef, useState } from 'react'
import * as StyledStandardBasketButton from './standardBasketButton.styles'
import { StrippedButton } from 'components/base/buttons/buttons'
import { useDispatch } from 'react-redux'
import { updateBasketItem } from 'reducers/basketReducer'
import IconMinus from 'icons/minus.svg'
import IconPlus from 'icons/plus.svg'
import { getProductBaseQuantity, getFixedFloatingPoint } from 'helpers/productHelpers/productHelpers'
import useTypedSelector from 'interfaces/useTypedSelector'
import { setActiveModal } from 'reducers/modalReducer'
import { useUpdateEffect } from 'react-use'
import { BasketProduct } from '@local-types/products/basketProduct'
import StorefrontProduct from '@local-types/products/storefrontProduct'
import useActiveCompany from 'hooks/useActiveCompany'
import toastMessage from 'components/utils/toasts'

interface Props {
	product: StorefrontProduct
	packageSelected: boolean
	kgSelected: boolean
	quantity?: number
	basketProduct: BasketProduct | null
	offsetLeft?: boolean
	itemUnavailable?: boolean
	initialQuantity?: number
}

const StandardBasketButton: FC<Props> = ({
	product,
	packageSelected,
	kgSelected,
	basketProduct,
	offsetLeft,
	initialQuantity,
	itemUnavailable = false,
	quantity = null,
}) => {
	const [editingQuantity, setEditingQuantity] = useState(false)
	const [customQuantityInput, setCustomQuantityInput] = useState<number | undefined>()
	const inputRef = useRef<HTMLInputElement>(null)
	const dispatch = useDispatch()
	const { authState } = useTypedSelector(({ auth }) => auth)
	const baseQuantity = quantity || getProductBaseQuantity(product, packageSelected)
	const selectedUnit = kgSelected ? 'kg' : 'stk'
	const { activeRelation } = useActiveCompany()

	const handleCustomInput = (e: ChangeEvent<HTMLInputElement>) => {
		const num = e.target.valueAsNumber
		if (isNaN(num) || num < 0) {
			setCustomQuantityInput(undefined)
		} else {
			setCustomQuantityInput(e.target.valueAsNumber)
		}
	}

	const handleBasketButtonClick = (q: number) => {
		if (authState === 'notLoggedIn') return dispatch(setActiveModal('not-logged-in'))
		if (!activeRelation || !activeRelation.canSubmitOrders) return dispatch(setActiveModal('no-active-relation'))
		const quantity = getFixedFloatingPoint(q)

		dispatch(
			updateBasketItem({
				product,
				quantity,
				selectedUnit,
				isPackage: packageSelected,
			})
		)
	}

	const setFixedInput = (val: number) => setCustomQuantityInput(getFixedFloatingPoint(val))

	const resetInputValue = (value: number) => {
		setFixedInput(value)
		handleBasketButtonClick(value)
		setCustomQuantityInput(value)
		setEditingQuantity(false)
	}

	const handleCustomInputSubmit = (value?: number) => {
		if (!value && value !== 0) return
		const remainder = value % baseQuantity
		const invalidInput = remainder !== 0

		// Round value
		const rounded = value - remainder
		const roundedDown = value === 0 || rounded > 0 ? rounded : baseQuantity
		const roundedUp = invalidInput ? roundedDown + baseQuantity : rounded

		// Find next valid value
		const roundedDownReminder = value - roundedDown
		const roundedUpReminder = roundedUp - value
		const updated = roundedDownReminder < roundedUpReminder ? roundedDown : roundedUp

		// If input needs to be rounded, raise error
		if (invalidInput) {
			toastMessage({
				type: 'info',
				message: `Ekki er hægt að versla ${value} ${
					kgSelected ? 'kg.' : 'stk.'
				} í pakkningavís. Það eru ${baseQuantity} ${
					kgSelected ? 'kg.' : 'stk.'
				} í pakkningu. Valinn var næsti mögulegi fjöldi.`,
			})
		}

		// Change input
		resetInputValue(updated)
	}

	useUpdateEffect(() => {
		if (basketProduct) {
			dispatch(
				updateBasketItem({
					product,
					quantity: baseQuantity,
					selectedUnit,
					isPackage: packageSelected,
				})
			)
		}
	}, [packageSelected, kgSelected])

	const showNotAvailableToast = () => {
		toastMessage({
			type: 'warning',
			message: 'Varan er ekki lengur í sölu.',
		})
	}

	const toggleInputMode = () => {
		if (!editingQuantity) {
			setCustomQuantityInput(basketProduct?.quantity || 0)
		}
		setEditingQuantity(!editingQuantity)
	}

	return (
		<StyledStandardBasketButton.Wrapper>
			{basketProduct !== null ? (
				<>
					<StyledStandardBasketButton.QuantityIcon
						pl="3rem"
						offsetLeft={offsetLeft ? '-3rem' : undefined}
						onClick={() => handleBasketButtonClick(basketProduct.quantity - baseQuantity)}>
						<IconMinus />
					</StyledStandardBasketButton.QuantityIcon>
					<StyledStandardBasketButton.QuantityIndicator onClick={toggleInputMode}>
						<StyledStandardBasketButton.QuantityIndicatorInner isInputting={editingQuantity}>
							{editingQuantity ? (
								<StyledStandardBasketButton.QuantityNumberInput
									required
									autoFocus
									ref={inputRef}
									type="number"
									id="number-input"
									name="number-input"
									placeholder="Fjöldi"
									value={customQuantityInput}
									onChange={handleCustomInput}
									onBlur={() => handleCustomInputSubmit(customQuantityInput)}
									onKeyDown={e => (e.key === 'Enter' ? handleCustomInputSubmit(customQuantityInput) : null)}
									autoCapitalize="none"
								/>
							) : (
								`${basketProduct.quantity} ${basketProduct.selectedUnit}`
							)}
						</StyledStandardBasketButton.QuantityIndicatorInner>
						{`${editingQuantity ? basketProduct.selectedUnit : ''} í körfu`}
					</StyledStandardBasketButton.QuantityIndicator>
					<StyledStandardBasketButton.QuantityIcon
						pr="3rem"
						onClick={() => handleBasketButtonClick(basketProduct.quantity + baseQuantity)}
						disabled={itemUnavailable}>
						<IconPlus />
					</StyledStandardBasketButton.QuantityIcon>
				</>
			) : (
				<StyledStandardBasketButton.AddToBasketWrapper>
					<StrippedButton
						style={{ paddingBottom: '1rem' }}
						disabled={itemUnavailable}
						onClick={() =>
							itemUnavailable ? showNotAvailableToast() : handleBasketButtonClick(initialQuantity || baseQuantity)
						}>
						Setja í körfu
					</StrippedButton>
					<StyledStandardBasketButton.QuantityIcon
						pr="3rem"
						onClick={() =>
							itemUnavailable ? showNotAvailableToast() : handleBasketButtonClick(initialQuantity || baseQuantity)
						}>
						<IconPlus />
					</StyledStandardBasketButton.QuantityIcon>
				</StyledStandardBasketButton.AddToBasketWrapper>
			)}
		</StyledStandardBasketButton.Wrapper>
	)
}

export default StandardBasketButton
