import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

interface IconProps {
	pl?: string
	pr?: string
	offsetLeft?: string
	disabled?: boolean
}

export const QuantityIcon = styled.div<IconProps>`
	display: flex;
	cursor: pointer;
	padding: 1rem;
	padding-bottom: 2rem;
	padding-left: ${({ pl }) => pl || '1rem'};
	padding-right: ${({ pr }) => pr || '1rem'};

	${({ offsetLeft }) =>
		offsetLeft &&
		css`
			margin-left: ${offsetLeft};
		`}

	svg {
		height: 2rem;
		width: 2rem;
		fill: ${({ theme, disabled }) => (disabled ? theme.colors.gray : theme.colors.primary)};
		transition: all 300ms ease-in-out;
	}
	&:hover {
		svg {
			fill: ${({ theme, disabled }) => (disabled ? theme.colors.gray : theme.colors.secondary)};
		}
	}
	&:active {
		svg {
			transition: none;
			fill: ${({ theme, disabled }) => (disabled ? theme.colors.gray : theme.colors.green)};
		}
	}
`

interface QuantityIndicatorInnerProps {
	isInputting: boolean
}
export const QuantityIndicatorInner = styled.span<QuantityIndicatorInnerProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: text;
	margin-right: 0.5rem;
	${({ isInputting, theme }) =>
		!isInputting &&
		css`
			&:hover {
				color: ${theme.colors.primary};
				transition: color 200ms;
			}
		`}
`

export const QuantityNumberInput = styled.input`
	width: 4rem;
	height: 3rem;
	text-align: center;
	padding: 0;
	padding-right: 0.3rem;
	margin-right: 0.5rem;
	background: #fff;
	color: ${({ theme }) => theme.colors.textColor};
	font-weight: normal;
	font-size: 1.6rem;
	font-family: ${({ theme }) => theme.fonts.fontFamily};
	line-height: 100%;
	border: 1px solid ${({ theme }) => theme.colors.borderColor};
	border-radius: 5px;
	transition: border-color 200ms;
	position: relative;
	::placeholder {
		color: ${({ theme }) => theme.colors.gray};
	}
	&:hover,
	&:focus {
		border-color: ${({ theme }) => theme.colors.primary};
	}
	&[type='number']::-webkit-inner-spin-button,
	&[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
`

export const QuantityIndicator = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 1rem;
	color: ${({ theme }) => theme.colors.textDark};
	font-weight: bold;
	font-size: 16px;
	text-align: center;
	user-select: none;
`

export const AddToBasketWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`
